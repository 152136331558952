import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic01.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic02.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic03.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic04.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic05.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic06.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic07.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic08.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic09.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic11.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic12.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic13.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic14.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic15.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic16.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic17.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic18.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic19.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic20.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic21.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic22.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic23.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic24.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic25.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/8-june-1/pic26.JPG",
];

export default function LivingOneness() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">CLASS XI PRESENTATION: LIVING ONENESS</h1>

        <p>
          To raise awareness regarding the association between humans and the
          natural world, the class XI of Chinmaya Vidyalaya, New Delhi,
          showcased a Class Presentation on the theme – Living Oneness,
          depicting the importance to thrive in harmony with humans and nature.
          The presentation commenced with the Ganesh Vandana and it was followed
          by the Welcome Song – ‘Vasudhaiva Kutumbakam’ (World is one Family).
          The Dance performance on ‘Vishwa Shanti’ depicted the destruction of
          evil forces and collation with good forces. The students presented a
          ‘Global Trade’ Conference wherein they played the role of
          representatives of different countries discussing the trade
          restrictions and barriers faced by countries and solutions to them.
          The show concluded with the Food Stalls under the project ‘Learn to
          Earn – Learning Entrepreneurship to Live ’ that served various
          delicacies from across the world. The money earned from the stalls was
          donated for the contribution of underprivileged children
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
